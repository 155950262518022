import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { IconMail2, IconPhone, IconPlace } from "../components/icons/Icons"
import Map from "../components/common/Map"
import ThankYouHeroBG from "../components/ThankYouHeroBG"
import AdditionalForms from "../components/common/AdditionalForms"
import HowItWorks from "../components/common/HowItWorks"

const PageNotFound = () => {
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="Page Not Found | Sell Us Your Jewelry"
        description="Where can I sell my jewelry and watch for the most money? #1 place to sell watches and jewelry, sterling silver and GIA certified diamonds."
        noindex={true}
      />

      <section id="Watch Instant Quote Form">
        <ThankYouHeroBG>
          <div
            role={"presentation"}
            className="absolute z-0 -translate-y-1 transform px-10 text-5xl font-black text-white blur-sm md:py-10 md:text-8xl xl:text-9xl"
          >
            PAGE NOT FOUND
          </div>
          <div
            role={"presentation"}
            className="absolute z-0 translate-y-1 transform px-10 text-5xl font-black text-accent opacity-40 blur-sm md:py-10 md:text-8xl xl:text-9xl"
          >
            PAGE NOT FOUND
          </div>
          <div className="px-10 z-20 text-5xl font-black text-link md:py-10 md:text-8xl xl:text-9xl">
            PAGE NOT FOUND
          </div>
        </ThankYouHeroBG>
      </section>

      <AdditionalForms />

      <HowItWorks className={"w-11/12 mx-auto max-w-[1366px]"} />

      <div className={"container mx-auto mt-16 flex flex-col gap-y-16 px-4 md:p-0 "}>
        <hr className={"w-10/12 mx-auto py-10"} />
        <h2 className={"mx-auto text-center text-4xl uppercase"}>Still have Questions?</h2>
        <p
          className={
            "mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5 inline-block"
          }
        >
          Our highly trained specialists, coupled with our international sales reach, allow us to
          offer the very best prices for your jewelry.
        </p>

        <section className={"flex w-full flex-wrap gap-16 lg:flex-nowrap"}>
          <div
            className={
              "align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPlace />
            </div>
            <p className={"text-3xl uppercase"}>Visit Us</p>
            <p className={"mt-16 text-link"}>9595 Harding Avenue, Bal Harbour Florida 33154</p>
          </div>
          <div
            className={
              "align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPhone />
            </div>
            <p className={"text-3xl uppercase"}>Call Us</p>
            <a href={"tel:+13057706955"} className="mt-16 text-accent">
              tel: 305 770 6955
            </a>
          </div>
          <div className={"align-center flex w-full flex-col justify-center text-center lg:w-1/3"}>
            <div className={"mb-8 text-5xl text-accent"}>
              <IconMail2 />
            </div>
            <p className={"text-3xl uppercase"}>Email Us</p>
            <a className={"mt-16 text-accent"} href={"mailto:sell@grayandsons.com"}>
              sell@grayandsons.com
            </a>
          </div>
        </section>
      </div>

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default PageNotFound
